import React from "react";
import { Grid, makeStyles, Typography, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import ContactForm from "./ContactForm";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    marginTop: "1rem",
    [theme.breakpoints.down("md")]: {
      height: "unset",
      marginTop: "2rem",
      // paddingRight: '1.5rem',
      // paddingLeft: '1.5rem',
    },
  },
  containerBody: {
    [theme.breakpoints.down("md")]: {
      // height: 'unset',
      // marginTop: '2rem',
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
    },
  },
  blackBoldText: {
    fontWeight: 800,
    maxWidth: "70%",
    marginBottom: "1rem",
    marginTop: "0.5rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
      fontSize: 30,
    },
  },
  desc: {
    maxWidth: "60%",
    marginBottom: "2.25rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
}));

const Contact = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid container className={classes.container} id="Contact%20Us">
      <Grid item xs={12} md={6} className={classes.containerBody}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bolder" }}
        >
          Contact Us
        </Typography>
        {/* <Typography variant="h4" className={classes.blackBoldText}>
          Want to transform your ideas into reality?
        </Typography> */}
        <Typography variant="body2" className={classes.desc}>
          Let's connect. All we need from you is a scope of work, a budget and a
          timeline to provide you with a model that fits your requirement
          perfectly. We’ve got you covered.
        </Typography>
        <Typography style={{ color: "#7f8999", marginBottom: "1rem" }}>
          Feel free to contact us over phone or email
        </Typography>
        <Grid style={{ display: "flex", flexDirection: "column" }}>
          <Link component={RouterLink} to={{ hash: `#` }}>
            contact@unboxinnovations.com
          </Link>
          <Link
            component={RouterLink}
            to={{ hash: `#` }}
            style={{ marginTop: "0.25rem" }}
          >
            +91 8898 034045 / +91 9697 762855
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6}>
        <ContactForm />
      </Grid>
    </Grid>
  );
};

export default Contact;
