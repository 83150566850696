import React from "react";
import { Grid, makeStyles, Typography, Paper, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100vh",
    [theme.breakpoints.down("md")]: {
      height: "unset",
    },
  },
  boldBlackText: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 800,
      fontSize: 30,
      maxWidth: "75%",
    },
  },
}));

const Blogs = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { blogs } = store.ui;

  return (
    <Grid className={classes.container} id="Blog">
      <Typography
        variant="subtitle2"
        color="primary"
        style={{ fontWeight: "bolder" }}
      >
        Visit Our Blog
      </Typography>
      <Typography variant="h4" className={classes.boldBlackText}>
        The latest updates in Tech
      </Typography>
      <Grid container spacing={3}>
        {blogs.map((item, index) => (
          <Grid
            key={index}
            item
            xs={12}
            md={4}
            style={{
              height: "35rem",
            }}
          >
            <Paper
              style={{
                borderRadius: "unset",
                padding: "1rem",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Paper
                style={{
                  height: "50%",
                  width: "100%",
                  borderRadius: "unset",
                  backgroundColor: "#EEEEEE",
                  marginBottom: "1rem",
                }}
              ></Paper>
              <Typography color="primary">{item.name}</Typography>
              <Typography
                variant="subtitle2"
                style={{ fontWeight: "bold", marginBottom: "1rem" }}
              >
                {item.title}
              </Typography>
              <Typography style={{ color: "#7f8999", marginBottom: "1rem" }}>
                {item.text}
              </Typography>
              <Link component={RouterLink} to={{ hash: `#${item}` }}>
                Read More
              </Link>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Blogs;
