import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import ServicesPageOddComponent from './reusableComponents/ServicesPageOddComponent';
import ServicesPageEvenComponent from './reusableComponents/ServicesPageEvenComponent';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const ServicesPageBody = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { servicesPage } = store.ui;

  return (
    <>
      {servicesPage.map((item, index) => {
        return index % 2 === 0 ? (
          <ServicesPageOddComponent
            key={index}
            title={item.title}
            text={item.text}
            linkText={item.linkText}
          />
        ) : (
          <ServicesPageEvenComponent
            key={index}
            title={item.title}
            text={item.text}
            linkText={item.linkText}
          />
        );
      })}
    </>
  );
};

export default ServicesPageBody;
