import React from 'react';
import { Grid, makeStyles, Typography, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Image } from 'semantic-ui-react';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '90vh',
    paddingRight: '4rem',
    paddingLeft: '4rem',
    [theme.breakpoints.down('md')]: {
      height: 'unset',
      paddingRight: '1rem',
      paddingLeft: '1rem',
      alignItems: 'flex-start',
    },
  },
  servicesGrid: {
    marginTop: '2rem',
    // height: '10rem',
    // width: '12rem',
    marginBottom: '1rem',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  boldTextTitleBlack: {
    fontWeight: 'bold',

    [theme.breakpoints.down('sm')]: {
      fontSize: 30,
      fontWeight: 800,
    },
  },
  serviceTitle: {
    fontWeight: 800,
    marginBottom: '1rem',
    marginTop: '0.75rem',
    [theme.breakpoints.down('sm')]: {
      fontWeight: 800,
      fontSize: 18,
    },
  },
  serviceDescription: {
    maxWidth: '65%',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

const Services = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { services } = store.ui;

  return (
    <Grid className={classes.container}>
      <Typography variant="h6" color="primary" style={{ fontWeight: 'bolder' }}>
        We offer you
      </Typography>
      <Typography variant="h4" className={classes.boldTextTitleBlack}>
        Our Expertise
      </Typography>
      <Grid container>
        {services.map((item, index) => (
          <Grid
            item
            xs={12}
            md={4}
            className={classes.servicesGrid}
            key={index}
          >
            {/* <Paper
              style={{
                height: '3rem',
                width: '3rem',
                borderRadius: 'unset',
                backgroundColor: '#EEEEEE',
                marginBottom: '1rem',
              }}
            ></Paper> */}
            <Image src={item.icon} style={{ alignSelf: 'start' }} />
            <Typography variant="subtitle2" className={classes.serviceTitle}>
              {item.title}
            </Typography>
            <Typography variant="body2" className={classes.serviceDescription}>
              {item.text}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Services;
