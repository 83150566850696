import React from 'react';
import './App.css';
import { Provider } from 'react-redux';
import store from './store';
import {
  createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from '@material-ui/core/styles';
import MainRouter from './routers/MainRouter';
import { CssBaseline } from '@material-ui/core';

export let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#D5DBF9',
      main: '#EA3D1B',
      dark: '#A7ACDF',
      drawer: '#2A3042',
    },
    secondary: {
      main: '#2C2D43',
    },
    text: {
      primary: '#001334',
      secondary: '#919295',
    },
    background: {
      paper: '#fff',
      default: '#fbfbfb',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: 'Raleway, Roboto, sans-serif',
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 'unset',
      },
    },
  },
});

theme = responsiveFontSizes(theme);

function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MainRouter />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
