import React from "react";
import {
  Grid,
  makeStyles,
  Container,
  Typography,
  Paper,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import DomainVerticalCards from "./reusableComponents/DomainVerticalCards";
import DomainPartners from "./reusableComponents/DomainPartners";

const useStyles = makeStyles((theme) => ({
  gridContainer: {
    marginTop: "1rem",
    // justifyContent: 'center',
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  headingContainer: {
    padding: "5rem 7rem !important",
    height: 350,
    [theme.breakpoints.down("sm")]: {
      padding: "1rem !important",
      height: "unset",
    },
  },
  partnersContainer: {
    display: "flex",
    justifyContent: "space-between",

    [theme.breakpoints.down("sm")]: {
      display: "grid",
      gridTemplateColumns: "repeat(2,1fr)",
      gridColumnGap: "10px",
      gridRowGap: "10px",
    },
  },
  bolderBlackText: {
    fontWeight: "bolder",

    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      fontWeight: 800,
    },
  },
}));

const Domains = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { partners, domainsData } = store.ui;

  return (
    <Grid className={classes.gridContainer} id="Services">
      <Container
        maxWidth="md"
        style={{
          marginBottom: "3rem",
        }}
      >
        {/* <Typography style={{ marginBottom: '1rem' }}>Our Partners</Typography> */}
        {/* <Grid
          container
          className={classes.partnersContainer}
          justify="space-between"
          // style={{ border: '1px solid #000' }}
        >
          {partners.map((item, index) => (
            <Grid item sm={12} md={2} key={index}>
              <DomainPartners />
            </Grid>
          ))}
        </Grid> */}
      </Container>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} className={classes.headingContainer}>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "bolder" }}
          >
            At Unbox Innovation
          </Typography>
          <Typography variant="h4" className={classes.bolderBlackText}>
            Get a cape for your Ideas
          </Typography>
          <Typography style={{ marginTop: "2rem", color: "#001334" }}>
            Be at the forefront of your industry with an exceptional suite of
            new and emerging technologies
          </Typography>
        </Grid>
        {domainsData.map((item, index) => (
          <Grid item xs={12} md={3} key={index}>
            <DomainVerticalCards
              title={item.title}
              text={item.text}
              icon={item.icon}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default Domains;
