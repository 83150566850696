import React from "react";
import {
  Grid,
  makeStyles,
  Container,
  Typography,
  Paper,
  Link,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Image } from "semantic-ui-react";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "80vh",
    marginTop: "16rem",
    marginBottom: "4rem",
    [theme.breakpoints.down("md")]: {
      height: "unset",
      marginTop: "6rem",
    },
  },
  boldBlackText: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 800,
      fontSize: 30,
      maxWidth: "75%",
    },
  },
  scrollGrid: {
    display: "flex",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      padding: "unset",
      flexDirection: "column",
    },
    width: "100%",
    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
      scrollbarWidth: "none",
    },
  },
  scroll: {
    minWidth: 450,
    height: 340,
    borderRadius: "unset",
    margin: "10px",
    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      margin: "unset",
      marginTop: 24,
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "space-between",
    padding: "1rem",
  },
  desc: {
    marginTop: "2rem",
    maxWidth: "85%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  dragContainer: {
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  dragGrid: {
    width: 100,
    height: 100,
    borderRadius: "50%",
    border: "1px solid #001334",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginLeft: 10,
    marginRight: 10,
  },
}));

const Testimonials = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { testimonials } = store.ui;

  return (
    <Grid className={classes.container} id="Testimonials">
      <Container
        maxWidth="md"
        style={{ display: "flex", marginBottom: "2rem" }}
      >
        <Grid item xs={12} md={8}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bolder" }}
          >
            Testimonials
          </Typography>
          <Typography variant="h4" className={classes.boldBlackText}>
            Client Reviews
          </Typography>
          <Typography className={classes.desc}>
            Some of the outstanding reviews from clients who were satisfied from
            our overall experience
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.dragContainer}>
          <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Image src={require("../images/chevron-left.svg")} />
            <Grid className={classes.dragGrid}>
              <Typography>Drag</Typography>
            </Grid>
            <Image src={require("../images/chevron-right.svg")} />
          </Grid>
        </Grid>
      </Container>
      <Grid className={classes.scrollGrid} style={{ marginTop: "1rem" }}>
        {testimonials.map((item, index) => (
          <Paper
            elevation={0}
            className={classes.scroll}
            style={{ backgroundColor: item.color }}
            key={index}
          >
            {/* <Typography style={{ marginBottom: '2rem' }}>hey</Typography> */}
            <Image src={item.icon} style={{ marginBottom: "1rem" }} />
            <Grid style={{ color: "#fff" }}>
              <Typography variant="h6">{item.name}</Typography>
              <Image
                src={require("../images/quotation-mark-icon.svg")}
                style={{ marginBottom: "0.25rem", marginTop: "0.25rem" }}
              />
              <Typography>{item.text}</Typography>
            </Grid>
            <Link
              component={RouterLink}
              to={{ hash: `#${item}` }}
              style={{ color: "#fff" }}
            >
              View Project
            </Link>
          </Paper>
        ))}
      </Grid>
    </Grid>
  );
};

export default Testimonials;
