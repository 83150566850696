import React from "react";
import { Grid, makeStyles, Typography, Container } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Image } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    marginTop: "3rem",
    [theme.breakpoints.down("md")]: {
      height: "unset",
    },
  },
  image: {
    marginRight: "2rem",
  },
  imagesGrid: {
    marginTop: "2rem",
    // justifyContent: 'center',
  },
  images: {
    width: "100%",
    maxHeight: 300,
    // objectFit: 'scale-down',
  },
  boldBlackText: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontWeight: 800,
      fontSize: 30,
      maxWidth: "75%",
    },
  },
  desc: {
    marginTop: "2rem",
    maxWidth: "85%",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },
  arrowContainer: {
    alignSelf: "flex-end",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

const Portfolio = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid className={classes.container} id="Portfolio">
      <Container maxWidth="md" style={{ display: "flex" }}>
        <Grid item xs={12} md={8}>
          <Typography
            variant="subtitle2"
            color="primary"
            style={{ fontWeight: "bolder" }}
          >
            Our portfolio
          </Typography>
          <Typography variant="h4" className={classes.boldBlackText}>
            Custom-built projects
          </Typography>
          <Typography className={classes.desc}>
            Some full-fledged developed projects from end-to-end involving clean
            coding and agile practices
          </Typography>
        </Grid>
        <Grid item xs={12} md={4} className={classes.arrowContainer}>
          {/* <Grid style={{ display: "flex", justifyContent: "flex-end" }}>
            <Image
              src={require("../images/circularArrowLeft.svg")}
              className={classes.image}
            />
            <Image src={require("../images/circularArrowRight.svg")} />
          </Grid> */}
        </Grid>
      </Container>
      <Grid container spacing={3} className={classes.imagesGrid}>
        <Grid item xs={12} md={6}>
          <Image
            src={require("../images/upgrand.png")}
            className={classes.images}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Image
            src={require("../images/imfs.png")}
            className={classes.images}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Image
            src={require("../images/certilite.png")}
            className={classes.images}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Image
            src={require("../images/viru.png")}
            className={classes.images}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Portfolio;
