import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Container,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import LandingPageAppbar from "../components/LandingPageAppbar";
import { theme } from "../App";
import Footer from "../components/Footer";
import ServicesPageBanner from "../components/ServicesPageBanner";
import ServicesPageBody from "../components/ServicesPageBody";
import Services from "../components/Services";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    // borderBottom: '1px solid #EEEEEE',
  },
  appbar: {
    height: "10%",
    "&.MuiPaper-outlined": {
      border: "unset",
      borderBottom: "1px solid #EEEEEE",
    },
  },
  body: {
    height: "90%",
  },
  serviceFlex: {
    marginTop: "100px",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      marginTop: "300px",
    },
  },
  footer: {
    backgroundColor: theme.palette.text.primary,
    [theme.breakpoints.down("sm")]: {
      marginTop: "300px",
    },
  },
}));

const ServicesPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid id="some">
      <Paper
        className={classes.appbar}
        elevation={3}
        square={true}
        variant="outlined"
      >
        <Container style={{ height: "100%" }}>
          <LandingPageAppbar />
        </Container>
      </Paper>
      <Container className={classes.body}>
        <ServicesPageBanner />
      </Container>
      <Container>
        <ServicesPageBody />
        <Grid className={classes.serviceFlex}>
          <Services />
        </Grid>
      </Container>
      <Grid className={classes.footer}>
        <Footer />
      </Grid>
    </Grid>
  );
};

export default ServicesPage;
