import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import LandingPage from "../pages/LandingPage";
import ServicesPage from "../pages/ServicesPage";

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LandingPage />
        </Route>
        <Route exact path="/Services">
          <ServicesPage />
        </Route>
      </Switch>
    </Router>
  );
};

export default MainRouter;
