import React from "react";
import { Grid, makeStyles, Link, Hidden } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink, useHistory } from "react-router-dom";
import { Image } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
  },
  logoContainer: {
    display: "flex",
    alignItems: "center",
  },
  navLinks: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-end",
    },
  },
  links: {
    marginRight: "1rem",
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16,
    textDecoration: "none",
  },
  linkButton: {
    marginRight: "1rem",
    color: theme.palette.text.primary,
    fontWeight: 600,
    fontSize: 16,
    padding: "7px 30px 7px 30px",
    border: "2px solid",
    borderColor: theme.palette.primary.main,
    textDecoration: "none",
  },
  hamburger: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    backgroundColor: "#EA3D1B",
    padding: 8,
  },
  menuBar: {
    height: 3,
    width: "100%",
    backgroundColor: "#fff",
    margin: "4px 0",
    transition: "0.4s",
  },
  menuBar2: {
    height: 3,
    width: "100%",
    backgroundColor: "#fff",
    margin: "4px 0",
    transition: "0.4s",
  },
}));

const LandingPageAppbar = (props) => {
  const classes = useStyles();
  const { handleMenuClick } = props;
  const store = useSelector((state) => state);
  const history = useHistory();
  const { navItems } = store.ui;
  console.log(history);

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={4} className={classes.logoContainer}>
          <Image src={require("../images/landingPageLogo.svg")} href={"/"} />
        </Grid>
        <Hidden xsDown>
          <Grid item xs={8} className={classes.navLinks}>
            {navItems.map((item, index) => (
              <a
                key={index}
                // href={
                //   item === "Home"
                //     ? "/"
                //     : item === "Services"
                //     ? `/${item}`
                //     : history.location.pathname === "/Services"
                //     ? `/#${item}`
                //     : `#${item}`
                // }
                href={item === "Home" ? "/" : `#${item}`}
                className={
                  item === "Contact Us" ? classes.linkButton : classes.links
                }
              >
                {item}
              </a>
            ))}
          </Grid>
        </Hidden>
        <Hidden smUp>
          <Grid
            className={classes.navLinks}
            item
            xs={8}
            onClick={() => handleMenuClick()}
          >
            <Grid className={classes.hamburger}>
              <div className={classes.menuBar}></div>
              <div className={classes.menuBar2}></div>
              <div className={classes.menuBar}></div>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>
    </>
  );
};

export default LandingPageAppbar;
