import React from "react";
import { Grid, makeStyles, Paper, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    // width: '100%',
  },
}));

const BoardMemberCard = (props) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { name, title, text } = props;

  return (
    <Grid className={classes.container}>
      <Paper
        style={{
          height: "20rem",
          width: "20rem",
          borderRadius: "unset",
          backgroundColor: "#EEEEEE",
          marginBottom: "1rem",
        }}
      ></Paper>
      <Typography
        variant="subtitle2"
        style={{
          fontWeight: "bold",
          marginBottom: "1rem",
          width: "20rem",
        }}
      >
        {name}
      </Typography>
      <Typography
        style={{
          color: "#7f8999",
          marginBottom: "1rem",
          width: "20rem",
        }}
      >
        {title}
      </Typography>
      <Typography
        style={{
          color: "#7f8999",
          marginBottom: "1rem",
          width: "20rem",
        }}
      >
        {text}
      </Typography>
    </Grid>
  );
};

export default BoardMemberCard;
