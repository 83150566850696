import React from 'react';
import {
  Grid,
  makeStyles,
  Typography,
  Checkbox,
  FormHelperText,
  Button,
  LinearProgress,
  Slider,
  FormControlLabel,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // display: 'flex',
    // flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      marginTop: '2rem',
    },
  },
  firstTwoGrids: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      marginBottom: 'unset',
    },
  },
  textField: {
    width: '48%',
    backgroundColor: '#fff',
    boxShadow: '0px 0px 30px #54545421',
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        border: 'none',
      },
      // border: 'none',
    },
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginBottom: '1rem',
    },
  },

  servicesGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      display: 'unset',
    },
  },
  buttonGrid: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '1rem',
    alignItems: 'center',
    marginTop: '2rem',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column-reverse',
      paddingRight: '1rem',
      paddingLeft: '1rem',
    },
  },
  label: {
    color: '#8c95a3',
  },
  formLabel: {
    color: '#7f8999',
  },
  button: {
    width: '50%',
    marginRight: '2rem',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      marginRight: 'unset',
      marginTop: '1rem',
    },
  },

  TermsText: {
    color: '#7f8999',
    marginBottom: '1rem',
    [theme.breakpoints.down('md')]: {
      // marginBottom: '4rem',
      marginTop: '2rem',
      // marginRight: '1rem',
      // marginLeft: '1rem',
    },
  },
}));

const ContactForm = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const handleOnSubmit = (data, actions) => {
    console.log(data);
  };

  return (
    <Formik
      initialValues={{
        fullName: '',
        companyName: '',
        email: '',
        phone: '',
        services: [],
        budget: '',
        about: '',
      }}
      validate={(values) => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        }
        // else if (
        //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
        // ) {
        //   errors.email = 'Invalid email address';
        // }
        else if (!values.password) {
          errors.password = 'Required';
        }
        return errors;
      }}
      onSubmit={handleOnSubmit}
    >
      {({ submitForm, isSubmitting }) => (
        <Form className={classes.formContainer}>
          <Grid className={classes.firstTwoGrids}>
            <Field
              component={TextField}
              type="fullName"
              name="fullName"
              required
              variant="outlined"
              id="fullName"
              className={classes.textField}
              placeholder="Full Name"
            />
            <Field
              component={TextField}
              type="companyName"
              name="companyName"
              required
              variant="outlined"
              id="companyName"
              className={classes.textField}
              placeholder="Company Name (optional)"
            />
          </Grid>
          <Grid className={classes.firstTwoGrids}>
            <Field
              component={TextField}
              type="email"
              name="email"
              required
              variant="outlined"
              id="email"
              className={classes.textField}
              placeholder="Email Address"
            />
            <Field
              component={TextField}
              type="phone"
              name="phone"
              required
              variant="outlined"
              id="phone"
              className={classes.textField}
              placeholder="Phone No."
            />
          </Grid>
          <Typography variant="subtitle1" className={classes.label}>
            Select Services
          </Typography>
          <Grid className={classes.servicesGrid}>
            <FormControlLabel
              control={<Checkbox name="checkedC" />}
              label="Web"
            />
            <FormControlLabel
              control={<Checkbox name="checkedC" />}
              label="IOS"
            />
            <FormControlLabel
              control={<Checkbox name="checkedC" />}
              label="Android"
            />
            <FormControlLabel
              control={<Checkbox name="checkedC" />}
              label="UI/UX"
            />
            <FormControlLabel
              control={<Checkbox name="checkedC" />}
              label="Other"
            />
          </Grid>
          <Typography variant="subtitle1" className={classes.label}>
            Your Budget
          </Typography>
          <Slider />
          <Field
            component={TextField}
            multiline={true}
            rows={8}
            type="about"
            name="about"
            required
            variant="outlined"
            id="about"
            className={classes.questionField}
            fullWidth
            placeholder="About your Project"
          />
          <Grid className={classes.buttonGrid}>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
            >
              <Typography>Submit</Typography>
            </Button>
            <FormControlLabel
              classes={{ label: classes.formLabel }}
              control={<Checkbox name="checkedC" />}
              label="Register to our Newsletter and get daily updates"
            />
          </Grid>
          <Typography variant="caption" className={classes.TermsText}>
            By clicking on ‘Submit’ you agree to the terms and conditions,
            privacy policy and allow us to send you our NDA.
          </Typography>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
