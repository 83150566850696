import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Container,
  Hidden,
  Collapse,
  Typography,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import LandingPageAppbar from "./LandingPageAppbar";
import LandingPageBannerBody from "./LandingPageBannerBody";
import { Link as RouterLink, useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100vh",
    borderBottom: "1px solid #EEEEEE",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  appbar: {
    height: "10%",
    "&.MuiPaper-outlined": {
      border: "unset",
      borderBottom: "1px solid #EEEEEE",
    },
  },
  body: {
    height: "90%",
  },
  menuItemContainer: {
    backgroundColor: "#fff",

    width: "100%",
    paddingBottom: 10,
    paddingTop: 10,
    paddingRight: 30,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  link: {
    textDecoration: "none",
    color: "#EA3D1B",
    marginTop: 6,
    marginBottom: 6,
    fontWeight: "bold",
  },
}));

const LandingPageBanner = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [checked, setChecked] = React.useState(false);
  const handleMenuClick = (event) => {
    setChecked((prev) => !prev);
    // console.log('clicked !');
  };

  return (
    <Grid className={classes.container}>
      <Paper
        className={classes.appbar}
        elevation={3}
        square={true}
        variant="outlined"
      >
        <Container style={{ height: "100%" }}>
          <LandingPageAppbar
            handleMenuClick={handleMenuClick}
            checked={checked}
          />
        </Container>
      </Paper>
      <Grid className={classes.body}>
        {/* <Container style={{ height: '100%' }}> */}
        <Hidden smUp>
          <Collapse in={checked} timeout={600}>
            <Paper elevation={0} className={classes.menuItemContainer}>
              <Typography
                variant="h3"
                component={RouterLink}
                to="/"
                className={classes.link}
              >
                Home
              </Typography>
              <Typography
                variant="h3"
                component={RouterLink}
                to="/services"
                className={classes.link}
              >
                Service
              </Typography>
              <Typography
                variant="h3"
                component={RouterLink}
                to={{ hash: `#Portfolio` }}
                className={classes.link}
              >
                Portfolio
              </Typography>
              <Typography
                variant="h3"
                component={RouterLink}
                to={{ hash: `#Blog` }}
                className={classes.link}
              >
                Blog
              </Typography>
              <Typography
                variant="h3"
                component={RouterLink}
                to={{ hash: `#Company` }}
                className={classes.link}
              >
                Company
              </Typography>
              <Typography
                variant="h3"
                component={RouterLink}
                to={{ hash: `#Contact Us` }}
                className={classes.link}
              >
                Contact Us
              </Typography>
            </Paper>
          </Collapse>
        </Hidden>
        <LandingPageBannerBody />
        {/* </Container> */}
      </Grid>
    </Grid>
  );
};

export default LandingPageBanner;
