import React from "react";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import BoardMemberCard from "./reusableComponents/BoardMemberCard";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "86vh",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      height: "unset",
    },
  },
  leftGrid: {
    width: "40%",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  rightGrid: {
    display: "flex",
    justifyContent: "space-evenly",
    // alignItems: "center",
    flexDirection: "row",
    [theme.breakpoints.down("xs")]: {
      padding: "unset",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "flex-start",
    },
    width: "100%",

    overflowX: "scroll",
    "&::-webkit-scrollbar": {
      height: 5,
    },
    // '&::-webkit-scrollbar-track': {
    //   background: 'c#4c4c4',
    // },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#EA3D1B",
      borderRadius: 20,
    },
  },
  scroll: {
    // minWidth: 550,
    margin: "10px",

    [theme.breakpoints.down("xs")]: {
      minWidth: "unset",
      margin: "10px",
    },
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
}));

const BoardMembers = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { boardMembers } = store.ui;

  return (
    <Grid className={classes.container} id="Company">
      <Grid className={classes.leftGrid}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bolder" }}
        >
          On the Board
        </Typography>
        <Typography variant="h4" style={{ fontWeight: "bold" }}>
          A dynamic mix of experts and industry leaders to help you unbox
        </Typography>
        <Typography style={{ marginTop: "2rem", maxWidth: "85%" }}>
          We bring a wealth of skills and experience from a wide range of
          backgrounds.
        </Typography>
      </Grid>
      <Grid className={classes.rightGrid}>
        {boardMembers.map((item, index) => (
          <Grid className={classes.scroll} key={index}>
            <BoardMemberCard
              name={item.name}
              title={item.title}
              text={item.text}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default BoardMembers;
