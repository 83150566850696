const { createSlice } = require("@reduxjs/toolkit");

const UiReducer = createSlice({
  name: "ui",
  initialState: {
    navItems: [
      "Home",
      "Services",
      "Portfolio",
      "Blog",
      "Company",
      "Contact Us",
    ],
    partners: [0, 1, 2, 3],
    domainsData: [
      {
        title: "Native Mobile Apps",
        icon: require("../images/mobile-app-development-icon.svg"),
        text: "Mobile apps custom-made using React and Flutter frameworks to grow your business by providing users a platform to engage on.",
      },
      {
        title: "Blockchain Development",
        icon: require("../images/blockchain-development-icon.svg"),
        text: "Expertise in blockchain development using Ethereum to provide secure, efficient and decentralized web applications",
      },
      {
        title: "DevOps Services",
        icon: require("../images/devOps-icon.svg"),
        text: "Specialized Devops services ensuring smooth and automated workflow using CI/CD, Dockerization, Kubernetes etc.",
      },
      {
        title: "Advanced Web Applications",
        icon: require("../images/python-development-icon.svg"),
        text: "Paving the way for the future of web applications, using frontend frameworks like React/Angular and backend frameworks like Python/Django that we just happen to specialize in",
      },

      {
        title: "AI/ML Solutions",
        icon: require("../images/custom-software-development-icon.svg"),
        text: "AI/ML solutions to help turn complex problems into intelligent business outcomes using libraries like Scikit Learn and Tensorflow framework.",
      },
      {
        title: "Cloud Server Architecture",
        icon: require("../images/openEdX-development-icon.svg"),
        text: "Assisting you to provide flexibility, storage, sharing, and easy accessibility using Amazon Web Services (AWS), Azure, and Google Cloud Platform (GCP) cloud services.",
      },
    ],
    services: [
      {
        title: "Industry Insights ",
        icon: require("../images/services-web-development-icon.png"),
        text: "With over 10+ years experience in the field, we offer you our knowledge of industries like Fintech, Edutech, and Ecommerce.",
      },
      {
        title: "Technical Expertise",
        icon: require("../images/services-openEdx-development.png"),
        text: "We specialise in a variety of open source platforms like OpenEdx, Pacbot, Blockcerts, etc. We also specialise in premium web development services like Devops, cloud and blockchain",
      },
      {
        title: "Transparency",
        icon: require("../images/services-app-development.png"),
        text: "Transparency is our priority. We ensure you’re in loop at every step of the way",
      },
      {
        title: "Agile Methodology",
        icon: require("../images/services-python-development-icon.png"),
        text: "We specialize in agile methodology, to encourage timely inspection and adaptation on-the-go.",
      },
      {
        title: "Dedicated Scrum Master ",
        icon: require("../images/services-blockchain-icon.png"),
        text: "Our biggest asset, a dedicated scrum master to focus on and prioritize features and tasks.",
      },
      {
        title: "Dedicated Project Manager",
        icon: require("../images/services-streamline-icon.png"),
        text: "We assign a dedicated project manager for ongoing support, communications and to ensure smooth execution of the project.",
      },
    ],
    boardMembers: [
      {
        name: "Paras Botadra",
        title: "Co-Founder",
        text: "10+ years experience in tech,tech and tech",
      },
      {
        name: "Raghav Ghai",
        title: "Co-Founder",
        text: "8+ years of experience in Business Management and Operations.",
      },
      // {
      //   name: "Daaniyal Shaikh ",
      //   title: "Project Manager",
      //   text: "",
      // },
    ],
    testimonials: [
      {
        name: "IMFS",
        icon: require("../images/testimonial-imfs-icon.png"),
        text: "Motivated individuals who work hard and complete their daily assignment which helps in the deadline and complete the project full-fledged.",
        color: "#FEB556",
      },
      {
        name: "UpGrad",
        icon: require("../images/testimonial-upgrad-icon.png"),
        text: "The team carries a clean and lean practice while developing applications and do not take their work lightly. Their methodology inspires them to work upright.",
        color: "#FF4D5C",
      },
      {
        name: "Intelligent Machine Lab",
        icon: require("../images/testimonial-IML-Logo.png"),
        text: "Extremely satisfied with the overall experience of the developers who took their work seriously and got the job done without any complaints.",
        color: "#63C3FF",
      },
    ],
    blogs: [
      {
        name: "FLUTTER - The Blooming Language",
        title: "Building App with Flutter? Here's what you must know!",
        text: "Google created a free and open-source mobile UI framework called Flutter and released it in May 2017. Flutter allows the developers to create a native...",
      },
      {
        name: "DATA SCIENCE makes Projects better",
        title: "Uses of machine learning technology in mobile applications",
        text: "Machine learning is a subset of Artificial Intelligence (AI) that allows a system to learn, explore, and generate outputs without any human interventi...",
      },
      {
        name: "APP Development Essentials",
        title: "Mobile App Architecture Basics of Mobile App Development",
        text: "In this era, the phase of ‘anything is possible’ started to unfold itself. To boost the business value, companies are opting for mobile applications a...",
      },
    ],
    links: [
      "Home",
      "Services",
      "Portfolio",
      "Testimonials",
      "Company",
      "Blog",
      "Contact Us",
    ],
    errorMessage: "",
    servicesUi: true,
    servicesPage: [
      {
        title: "Android Application",
        text: "Converting your vision into reality takes a lot of effort, spark, and a little bit of luck. Thankfully, Unbox has all of that and much more, when it comes to developing android applications! Today’s tech makes it possible to run applications on devices ranging from watches to smart TVs. Unbox helps you crack the code for building a superhit android application, as per your requirements, within your budget, and well within your timeline. Get your dream out of your head, and into the Google Play Store!",
        linkText: "Check Related Projects",
      },
      {
        title: "Blockchain Development",
        text: "The core of technological focus today lies on a blockchain. The revolutionary tech that powers Bitcoin and millions of other cryptocurrencies, blockchain offers a new era of products and applications for developers. Our experience and research allow us to develop decentralized apps, or dApps, which are business-oriented and ready for adoption. Enterprise blockchain solutions, smart contract development, wallet and crypto exchange solutions- we build it all.",
        linkText: "Check Related Projects",
      },
      {
        title: "DevOps Services",
        text: "The integration of development and operations has given birth to what the world calls DevOps. With Unbox Innovations’ DevOps services, you can be sure of achieving cent per cent efficiency anywhere in the developmental stack. Continuous integration and delivery of updates throughout the process allows for easy transition into the DevOps as a service model for your business. Assessment, automation and management- the three pillars that Unbox builds its DevOps services on.",
        linkText: "Check Related Projects",
      },
      {
        title: "Python Development",
        text: "In a world where traffic and data are king, building web applications that are robust, scalable, and portable is a must. With Unbox’s Python development services, building responsive applications across multiple platforms becomes that much easier. What’s more is that our dedicated project management makes it so that you don’t have to worry about even an ounce of the process!",
        linkText: "Check Related Projects",
      },
      {
        title: "OpenEdX Development",
        text: "This one is for the students, educators, and coaches out there. OpenEdX, a platform that allows you to build your own online learning courses and aids, has taken the education world by storm. Unbox’s OpenEdX development services provide you the ability to offer courses and training which are not only effective, but highly engaging as well. Taking care of all aspects of the environment, especially the Learning Management System, Unbox provides you with greater control over what you teach.",
        linkText: "Check Related Projects",
      },
      {
        title: "Custom Software Development",
        text: "Want to build something that truly screams you? Unbox’s custom software development services take care of each and every aspect of the process, right from building your ideal design to prototyping and branding. The focus lies on one thing- how will your customers perceive your product? Project management, quality assurance, and proper testing ensure that whatever you put out for your customers is a great impression of you as a brand.",
        linkText: "Check Related Projects",
      },
    ],
  },
  reducers: {
    createPost(state, action) {
      state.navItems = action.payload;
    },
    toggleServicesUi(state) {
      state.servicesUi = !state.servicesUi;
    },
  },
});

const { actions } = UiReducer;

export const { createPost, toggleServicesUi } = actions;

// export const toggleRecruiterModalDispatch = () => async (dispatch) => {
//   dispatch(toggleDragDropCandidateModal());
// };

export default UiReducer;
