import React from "react";
import { Grid, makeStyles, Typography, Button } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  mr20: {
    width: "20%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

const ServicesPageBanner = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid style={{ width: "80%" }}>
        <Typography
          variant="h6"
          color="primary"
          style={{ fontWeight: "bolder" }}
        >
          Our Services
        </Typography>
        <Typography variant="h2" style={{ fontWeight: "bold" }}>
          The Unbox Innovations Service Suite
        </Typography>
        <Typography
          style={{ marginTop: "2rem", marginBottom: "2rem", width: "90%" }}
        >
          Right from android applications, blockchain development, and DevOps
          services, to full-stack custom software development, Unbox Innovations
          encompasses the entire technological spectrum. Building innovative
          solutions that are as effective and efficient as they are eye candy,
          Unbox leverages powerful tech and efficient strategy to ensure that
          your product screams out your identity.
        </Typography>
        <Grid className={classes.ButtonGrid}>
          <Button variant="contained" color="primary" className={classes.mr20}>
            Tell us your Idea
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ServicesPageBanner;
