import React from 'react';
import { Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { Image } from 'semantic-ui-react';

const useStyles = makeStyles((theme) => ({
  paperContainer: {
    height: '100%',
    padding: '1.5rem',

    boxShadow: '0px 3px 6px #0000001A',
    display: 'flex',
    flexDirection: 'column',

    [theme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  titleText: {
    fontWeight: 'bolder',
    marginTop: 10,
    [theme.breakpoints.down('sm')]: {
      marginTop: 20,
      fontWeight: 800,
    },
  },
  descriptionText: {
    marginTop: 12,
  },
}));

const DomainVerticalCards = (props) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const { title, text, icon } = props;

  return (
    <Paper elevation={4} className={classes.paperContainer}>
      {/* <Paper
        square={true}
        style={{ height: 50, width: 50, backgroundColor: '#eeeef1' }}
      >
        hey
      </Paper> */}
      <Image src={icon} style={{ alignSelf: 'start' }} />
      <Typography variant="h6" className={classes.titleText}>
        {title}
      </Typography>
      <Typography
        style={{ color: '#7f8999' }}
        className={classes.descriptionText}
      >
        {text}
      </Typography>
    </Paper>
  );
};

export default DomainVerticalCards;
