import React from "react";
import {
  Grid,
  makeStyles,
  Paper,
  Typography,
  Button,
  Hidden,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { theme } from "../App";
import { Image } from "semantic-ui-react";

import ReactAnime from "react-animejs";
const { Anime, stagger } = ReactAnime;

const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: "95vh",
    marginBottom: "1rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
      marginTop: "2rem",
    },
  },
  paperContainer: {
    height: "10rem",
    width: "12rem",
    borderRadius: 20,
    marginTop: "1rem",
    marginBottom: "1rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    padding: "1rem",
  },
  image: {
    position: "absolute",
    zIndex: -1,
    left: -90,
    // objectFit: 'cover',
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      // alignItems: 'flex-start',
    },
  },
  getQuoteButton: {
    width: "25%",
    marginTop: "2rem",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      // marginRight: 'auto',
      // marginLeft: 'auto',
    },
  },
  boldTextTitle: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      fontWeight: 800,
    },
  },
}));

const ExperienceStats = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Hidden mdDown>
        <Anime
          initial={[
            {
              targets: "#image",

              loop: true,
              rotate: {
                value: 360,
                // duration: 1800,
                easing: "linear",
                duration: 6000,
                // loop: true,
              },
            },
          ]}
        >
          <Image
            src={require("../images/experienceStatsImage.svg")}
            className={classes.image}
            fluid={true}
            id="image"
          />
        </Anime>
      </Hidden>
      <Grid item xs={12} md={6} className={classes.leftContainer}>
        <Grid
          style={{
            height: "45%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end",
          }}
        >
          <Paper
            className={classes.paperContainer}
            style={{
              backgroundColor: theme.palette.primary.main,
              color: "#fff",
            }}
          >
            <Paper
              style={{ height: "3rem", width: "3rem", borderRadius: "unset" }}
            ></Paper>
            <Typography>10+</Typography>
            <Typography>Years of Experience</Typography>
          </Paper>
        </Grid>
        <Grid
          style={{
            height: "55%",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "flex-start",
          }}
        >
          <Paper className={classes.paperContainer}>
            <Paper
              elevation={0}
              style={{
                height: "3rem",
                width: "3rem",
                borderRadius: "unset",
                backgroundColor: "#EEEEEE",
              }}
            ></Paper>
            <Typography>102</Typography>
            <Typography>Satisfied Clients</Typography>
          </Paper>
          <Paper
            className={classes.paperContainer}
            style={{ marginTop: "5rem" }}
          >
            <Paper
              elevation={0}
              style={{
                height: "3rem",
                width: "3rem",
                borderRadius: "unset",
                backgroundColor: "#EEEEEE",
              }}
            ></Paper>
            <Typography>200+</Typography>
            <Typography>Projects Built</Typography>
          </Paper>
        </Grid>
      </Grid>
      <Grid item xs={12} md={6} className={classes.rightContainer}>
        <Typography
          variant="subtitle2"
          color="primary"
          style={{ fontWeight: "bolder" }}
        >
          Overview
        </Typography>
        <Typography variant="h3" className={classes.boldTextTitle}>
          Building your business from the ground up
        </Typography>
        <Typography style={{ marginTop: "2rem", maxWidth: "85%" }}>
          Unlock the potential of emerging technologies to transform your
          business, and position yourself as the winner you’ve always wanted to
          be.
        </Typography>
        <Button
          variant="outlined"
          color="primary"
          className={classes.getQuoteButton}
          href="#Contact%20Us"
        >
          <Typography style={{ color: "#000" }}>Get Quote</Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default ExperienceStats;
