import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Container,
  Link,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { Image } from "semantic-ui-react";

const useStyles = makeStyles((theme) => ({
  container: {
    // height: '30vh',
    [theme.breakpoints.down("md")]: {
      marginTop: "2rem",
    },
  },
  exploreText: {
    color: "#fff",
    paddingTop: "5rem",
    marginBottom: "1rem",
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  linksGrid: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5rem",
    [theme.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  // logoIcon: {
  //   height: 20,
  //   width: 20,
  // },
  gridContainer: {
    paddingBottom: "2rem",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column-reverse",
      alignItems: "center",
    },
  },
  logoGrid: {
    display: "flex",
    alignItems: "center",
    // justifyContent: 'space-between',
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      // alignItems: 'center',
      justifyContent: "space-between",
      marginTop: "3rem",
    },
  },
  links: {
    color: "#7D8797",
    textDecoration: "none",
    [theme.breakpoints.down("sm")]: {
      marginTop: "0.25rem",
      marginBottom: "0.25rem",
    },
  },
  socialMediaIcon: {
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0.45rem",
      marginRight: "0.45rem",
    },
  },
  allRightsGrid: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "3rem",
    [theme.breakpoints.down("sm")]: {
      alignItems: "center",
      marginTop: "3rem",
      marginLeft: "unset",
    },
  },
}));

const Footer = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const { links } = store.ui;

  return (
    <Container>
      <Grid className={classes.container}>
        <Typography className={classes.exploreText}>EXPLORE</Typography>
        <Grid className={classes.linksGrid}>
          {links.map((item, index) => (
            <a
              key={index}
              // href={
              //   item === "Home"
              //     ? "/"
              //     : item === "Services"
              //     ? `/${item}`
              //     : history.location.pathname === "/Services"
              //     ? `/#${item}`
              //     : `#${item}`
              // }
              href={item === "Home" ? "/" : `#${item}`}
              className={classes.links}
            >
              {item}
            </a>
          ))}
        </Grid>
        <Grid container className={classes.gridContainer}>
          <Grid item xs={12} md={10} className={classes.logoGrid}>
            <Image
              src={require("../images/brand-logo-white.svg")}
              className={classes.logoIcon}
            />
            <Grid className={classes.allRightsGrid}>
              <Typography variant="caption" style={{ color: "#7D8797" }}>
                All rights reserved
              </Typography>
              <Typography variant="caption" style={{ color: "#fff" }}>
                Terms of Use | Privacy Policy | Cookies
              </Typography>
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            md={2}
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <Image
              src={require("../images/instaIcon.svg")}
              className={classes.socialMediaIcon}
            />
            <Image
              src={require("../images/facebookIcon.svg")}
              className={classes.socialMediaIcon}
            />
            <Image
              src={require("../images/linkedinIcon.svg")}
              className={classes.socialMediaIcon}
            />
            <Image
              src={require("../images/twitterIcon.svg")}
              className={classes.socialMediaIcon}
            />
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Footer;
