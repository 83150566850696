import React from "react";
import {
  Grid,
  makeStyles,
  Typography,
  Button,
  Container,
  Link,
  Hidden,
} from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center center",
    backgroundImage: "url(" + require("../images/bannerImage.png") + ")",
    resize: "cover",
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      backgroundPosition: "right",
      padding: "50px 0px 50px 0px",
    },
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  ButtonGrid: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    marginTop: "4rem",
  },
  mr20: {
    marginRight: 20,
    fontWeight: 600,
  },
  mainButton: {
    marginRight: 20,
    fontWeight: 600,
    color: theme.palette.primary.main,
    backgroundColor: "#FFF",
  },
  subText: {
    fontWeight: 500,
    marginTop: 20,
    paddingRight: 320,
    [theme.breakpoints.down("sm")]: {
      paddingRight: 10,
      paddingTop: 100,
    },
  },
}));

const LandingPageBannerBody = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={8} className={classes.textContainer}>
        <Container style={{ width: "80%" }}>
          <Typography
            variant="h6"
            color="primary"
            style={{ fontWeight: "bolder" }}
          >
            Unbox Innovations,
          </Typography>
          <Typography style={{ fontWeight: 800, fontSize: "52px" }}>
            Propelling your Ideas
          </Typography>
          <Typography className={classes.subText}>
            Bespoke web development services using Blockchain, AI/ML, Python,
            Cloud etc
          </Typography>
          <Hidden xsDown>
            <Grid className={classes.ButtonGrid}>
              <Button
                variant="contained"
                color="primary"
                className={classes.mr20}
                href="#Contact%20Us"
              >
                Connect with us
              </Button>
              <Typography className={classes.mr20}>or</Typography>
              <Button
                variant="contained"
                className={classes.mainButton}
                onClick={() =>
                  window.open("https://us04web.zoom.us/meeting/schedule")
                }
              >
                Schedule a Zoom call
              </Button>
            </Grid>
          </Hidden>
        </Container>
      </Grid>
      {/* <Grid item xs={12} md={6}></Grid> */}
    </Grid>
  );
};

export default LandingPageBannerBody;
