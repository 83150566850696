import React from 'react';
import { Grid, makeStyles, Container } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import LandingPageAppbar from '../components/LandingPageAppbar';
import LandingPageBanner from '../components/LandingPageBanner';
import Domains from '../components/Domains';
import ExperienceStats from '../components/ExperienceStats';
import Services from '../components/Services';
import BoardMembers from '../components/BoardMembers';
import Portfolio from '../components/Portfolio';
import Testimonials from '../components/Testimonials';
import Blogs from '../components/Blogs';
import Contact from '../components/Contact';
import Footer from '../components/Footer';
import { theme } from '../App';

const useStyles = makeStyles((theme) => ({
  container: {},
}));

const LandingPage = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <>
      <LandingPageBanner />

      <Container>
        <Domains />
        <ExperienceStats />
        <Services />
        <BoardMembers />
        <Portfolio />
        <Testimonials />
        <Blogs />
        <Contact />
      </Container>
      <Grid style={{ backgroundColor: theme.palette.text.primary }}>
        <Footer />
      </Grid>
    </>
  );
};

export default LandingPage;
