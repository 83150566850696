import React from "react";
import { Grid, makeStyles, Paper, Typography, Link } from "@material-ui/core";
import { useSelector, useDispatch } from "react-redux";
import { Link as RouterLink } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
  },
  paperContainer: {
    height: "80vh",
    marginBottom: "-60px",
    [theme.breakpoints.down("sm")]: {
      height: "40vh",
      marginBottom: "0px",
    },
  },
  paper: {
    height: "100%",
    backgroundColor: "#F3F3F3",
  },
  infoContainer: {
    height: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-end",
    marginTop: 20,
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
  },
  title: {
    fontWeight: "bold",
  },
  text: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

const ServicesPageEvenComponent = (props) => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { title, text, linkText } = props;

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} md={7} className={classes.infoContainer}>
        <Grid style={{ width: "80%" }}>
          <Typography variant="h3" className={classes.title}>
            {title}
          </Typography>
          <Typography className={classes.text}>{text}</Typography>
          <Link component={RouterLink} to="#" underline="always">
            {linkText}
          </Link>
        </Grid>
      </Grid>
      <Grid item xs={12} md={4} className={classes.paperContainer}>
        <Paper className={classes.paper}></Paper>
      </Grid>
    </Grid>
  );
};

export default ServicesPageEvenComponent;
