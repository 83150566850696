import React from 'react';
import { Grid, makeStyles, Paper } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

const useStyles = makeStyles((theme) => ({
  partnersPaper: {
    borderRadius: 'unset',
    backgroundColor: '#eeeef1',
    height: 50,
  },
}));

const DomainPartners = () => {
  const store = useSelector((state) => state);
  const dispatch = useDispatch();
  const classes = useStyles();

  return (
    <Paper elevation={0} className={classes.partnersPaper}>
      hey
    </Paper>
  );
};

export default DomainPartners;
